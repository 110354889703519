:root {
    --agricaMainColor: black;
    --agricaMainSecondColor: black;
    --agricaMainBackground: white;
    --agricaMainSecondBackground: white;
    --agricaMainbutton: #d1614c;
}

body{
    color:var(--agricaMainColor) !important;
}

.MuiIconButton-colorInherit{
    color:var(--agricaMainColor) !important;
}

.site {
    color:var(--agricaMainSecondColor) !important;
}
.form-switch {
    color:var(--agricaMainSecondColor) !important;
}

.CardOverview {
    color: var(--agricaMainColor) !important;
}

.card a{
    color: var(--agricaMainColor) !important;
}

.tab-pane{
    color:var(--agricaMainColor);
}

form:last-child {
}

table{
    color:var(--agricaMainColor);
}

.headerMC {
    /*width: 92.4%;*/
    color:var(--agricaMainSecondColor);
}

#headerMCfooter{
}

.MainBackground{
}

.footerBox {
    color:var(--agricaMainColor) !important;
}

.modal-content{
    color:var(--agricaMainColor) !important;
}


.MuiPaper-elevation16{
    box-shadow: 0px 8px 10px -5px rgb(255 255 255 / 20%), 0px 16px 24px 2px rgb(255 255 255 / 14%), 0px 6px 30px 5px rgb(255 255 255 / 12%)  !important;
}

.boxShadowCard{
    box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)  !important;
}

.headerboxColor{
    color:var(--agricaMainColor) !important;
    opacity: 1 !important;
}

.overviewButtonDel{
    color:var(--agricaMainColor) !important;
}

.ContentWrapper {
    color:var(--agricaMainColor) !important;
}

.IssueMenu{
    color:var(--agricaMainSecondColor);
}
.cr-search{
    display: none;
}

.langBtn {
    color:var(--agricaMainColor) !important;
}

.btn-primary {
    color:var(--agricaMainSecondBackground);
    background-color: var(--agricaMainbutton);
    border-color: var(--agricaMainbutton);
}

.btn-primary:hover {
    color: var(--agricaMainSecondBackground);
    background-color: var(--agricaMainbutton);
    border-color: var(--agricaMainbutton);
}

.scrollbar-container {
    background-color:rgba(0,0,0,.06);
}

.app-sidebar > div{
    background:var(--agricaMainSecondBackground) !important;
}